import { ui, select } from '@owenscorning/pcb.alpha';
import { v4 as uuid } from 'uuid';
import Product from './Product';
import { migrateListToTree, migrateTreeToList, metadataVersion } from '../Content';
import { makeT } from '../../../../location/locales';
import _ from 'lodash';
import sectionDefaults from './data/paroc';

// when we migrate the data at rest in backend we can drop these methods
/* eslint-disable camelcase */
const productParocSectionIdGetter = (item, index) => (
  [
    'template.technical_information',
    'template.certifications',
    'template.promotions',
    'template.specifications_and_literature',
    'template.solutions_and_related_products',
  ][index]
);

const productModuleIdGetter = (item, index) => (
  item.templated ? `template.${_.snakeCase(item.type)}` : `user.${item.__uuid || uuid()}`
);

export const migrateOldParocToNew = (contents, metadata) => {
  contents = _.cloneDeep(contents);
  contents.sections.forEach(section => {
    section.modules = migrateListToTree(section.modules, productModuleIdGetter);
  });
  contents.sections = migrateListToTree(contents.sections, productParocSectionIdGetter);
  return { contents, metadata: _.cloneDeep(metadata || {}) };
};

export const migrateNewParocToOld = (contents, metadata) => {
  const { sections, ...restContents } = _.cloneDeep(contents);
  const newSections = (migrateTreeToList(sections) || []).map(section => {
    if (section) {
      const { modules, ...restSection } = section;
      return { modules: migrateTreeToList(modules) || [], ...restSection };
    } else {
      return null;
    }
  }).filter(_.identity);
  return { contents: { sections: newSections, ...restContents }, metadata: _.cloneDeep(metadata || {}) };
};
/* eslint-enable camelcase */

const onlyModules = [
  {
    type: 'TabbedContent',
    modules: [
      'BasicContent',
      'ContentAndImage',
      'FullBleedContent',
      'Gallery',
      'MediaObjectSet',
      'Reusable',
      'Table',
    ],
  },
];

const Paroc = Product(
  'Product::Paroc',
  ui`AttributeSet`({
    query: {
      name: 'Product Attributes',
      path: '/',
    },
    linked_object: select`~linked_object`,
  }),
  // param is named "sectionDefaults" but this is going to become new "template" param
  null,
  // this param is also unneeded in new approach, it's part of template
  {},
  {
    version: '0.1',
    displayName: 'Paroc PDP',
    paths: ['products'],
    allowsPIMLink: true,
    read: ({ build, contents, metadata, zipFile }) => {
      // this is just parameter position #3
      const template = sectionDefaults;

      if (_.isEmpty(contents)) {
        // new product, initialize with template
        const evaluatedTemplate = _.cloneDeep(_.isFunction(template) ? template(build, makeT(build.language || 'en')) : template);
        const result = {
          contents: evaluatedTemplate.contents,
          metadata: _.merge(evaluatedTemplate.metadata, _.cloneDeep(metadata)),
        };

        // convert to "list" format for pcb to work with
        return { ...migrateNewParocToOld(result.contents, result.metadata), zipFile };
      }
      if (metadataVersion(metadata) < 2) {
        // existing product, not migrated
        // do nothing because this data is already in lists for pcb
        return { contents, metadata, zipFile };
      }
      // existing product, already migrated
      // need to convert to lists for pcb
      // convert to "list" format for pcb to work with
      return { ...migrateNewParocToOld(contents, metadata), zipFile };
    },
    write: ({ contents, metadata }) => {
      // when we migrate the data at rest in backend we can drop this if
      if (metadataVersion(metadata) < 2) {
        // not migrated
        // leave it alone
        return { contents, metadata };
      }
      // already migrated
      // need to write list format back to tree
      return migrateOldParocToNew(contents, metadata);
    },
  },
  // this is driving only the title in sidebar, and what modules are allowed, need to fold into "template" param
  (build) => [
    {
      title: 'Technical Information',
      modules: ['Table', 'CardSet', 'BasicContent'],
      opts: { onlyModules },
    },
    {
      title: 'Certifications',
      modules: ['BasicContent'],
    },
    {
      title: 'Promotions',
      modules: ['BasicContent'],
    },
    {
      title: 'Specifications & Literature',
      unmovables: [true],
      modules: ['BasicContent', 'ItemList', 'Reusable'],
    },
    {
      title: 'Solutions & Related Products',
      modules: ['BlockLinkSet', 'BasicContent', 'ContentAndImage', 'CardSet'],
      opts: { onlyModules },
    },
  ],
  '/products',
  null,
  null
);

export default Paroc;
