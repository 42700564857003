import { ui, when, s } from '@owenscorning/pcb.alpha';
import { expandRef, wrapRef } from '../../../../data';
import { apiGet } from '../../../PageBuilder/helpers/api';
import getFormatFromMime from '../../../helpers/get_format_from_mime';
import FeaturedDocument from '../../../OC/oc-featured-document';
import useReference from '../../../../hooks/use_reference';
import getExtension from '../../../OC/utilities/file-extension';
import FileBadge from '../../../OC/oc-file-badge';
import _ from 'lodash';

// Assets
import IconPimLink from '../../../ComponentLibrary/icons/icon-pim-link';

let searchAbortController;

const refSearchMetadata = (startOpen, resultMapper) => ({
  startOpen,
  label: 'Document',
  dataset: 'documents',
  set: (value, path) => {
    // TODO: handle empty/null value?
    const ref = wrapRef('Document', value.id);
    expandRef(ref).then(result => resultMapper(result, path))
    return ref;
  },
  get: (value) => {
    const { results, error, loading } = useReference(value);
    if (loading) {
      return <i>Loading...</i>
    } else if (error) {
      return <span>{ error }</span>
    }
    return results;
  }
});

const mapping = {
  SpecificationsAndLiterature: {
    availableIn: ['www.owenscorning.com', 'www.paroc.com'],
    name: 'DMS Document',
    meta: {
      type: ui`Choices`.of({
        document: 'DMS',
        link: 'Link'
      })({
        label: 'Type',
        default: 'document',
        disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_"),
        tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_").then("Field connected to PIM product."),
        tipIcon: IconPimLink
      }),
      document: ui`Search`({
        ...refSearchMetadata(when`../document`.isnt.present.then(true).otherwise(false), (result, path) => {
          Board.Change(result.title, _.concat(_.initial(path), 'title'));
          Board.Change(result.short_description, _.concat(_.initial(path), 'description'));
        }),
        visible: when`../type`.is.equal.to('document')
      }),
      link: ui`Url`({
        label: 'Document Link',
        disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_"),
        tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_").then("Field connected to PIM product."),
        tipIcon: IconPimLink,
        visible: when`../type`.is.equal.to('link')
      }),
      title: ui`Text`({
        label: 'Document Title',
        controlled: 'value',
        disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_"),
        tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_").then("Field connected to PIM product."),
        tipIcon: IconPimLink,
        visible: when`../type`.is.equal.to('link').or.when`../document`.is.present
      }),
      featured: ui`Switch`({
        label: 'Featured?',
        visible: when`../document`.is.present
      }),
      description: ui`Text`({
        label: 'Document Description',
        controlled: 'value',
        disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_"),
        tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_").then("Field connected to PIM product."),
        tipIcon: IconPimLink,
        visible: when`../document`.is.present.then(true).otherwise(false)
      }),
      [s._]: ui`Tip`.of('Description is only used in "featured" documents.')({
        visible: when`../document`.is.present.then(true).otherwise(false)
      }),
    },
  },
  RichText: {
    availableIn: ['www.owenscorning.com', 'www.paroc.com', 'www.vidawool.com'],
    name: 'DMS Document',
    meta: {
      document: ui`Search`(refSearchMetadata(true, (result, path) => {
        Board.Change(result.title, _.concat(_.initial(path), 'title'));
      })),
      title: ui`Text`({
        label: 'Document Title',
        controlled: 'value',
        visible: when`../document`.is.present.then(true).otherwise(false)
      }),
    },
  },
  Accordion: {
    availableIn: ['www.owenscorning.com', 'www.paroc.com'],
    name: 'DMS Document',
    old_view: async ({ categories } = {}) => {
      return Promise.all((categories || []).map(({ documents = [], ...rest }) => {
        return Promise.all(documents.map(document => {
          if (!document) return null;
          if (document.type === 'link') return document;
          if (!document.document) return null;
          return (document.document.__data ? Promise.resolve(document.document.__data) : expandRef(document.document))
        })).then(expandedDocuments => {
          return {
            ...rest,
            documents: documents.map((d, i) => ({ ...d, document: { ...d.document, __data: expandedDocuments[i]?.publication_id ? expandedDocuments[i] : null } })).filter(Boolean)
          }
        })
      })).then(result => result.filter(Boolean));
    }
  },
  ProductsInSolution: {
    availableIn: ['www.owenscorning.com'],
    name: 'DMS Document',
    fetch_data: async ({ items = [] }) => {
      if (_.isEmpty(items)) return;

      const categories = items.map(async (x) => {
        if (!x?.product) return;

        const product = await expandRef(x?.product);
        if (!product) return;

        // For ProductsInSolution, documents now keep coming from the usual product setting path, but we also
        // want to return the visibility for these that might be located within the product->documents path
        return {
          productCategories: _.get(product, ['metadata', 'settings', 'documents', 'categories']),
          documentsVisibility: _.get(x, ['documents']) || []
        };
      });

      return Promise.all(categories);
    }
  },
  MediaObjectSet: {
    availableIn: ['www.owenscorning.com', 'www.paroc.com'],
    name: 'DMS Document',
    meta: {
      items: ui`List`.of({
        document: ui`Search`(refSearchMetadata(false, (result, path) => {
          Board.Change(result.analytics?.['doc-type'] || result.category, _.concat(_.initial(path), 'prehead'));
          Board.Change(result.title, _.concat(_.initial(path), 'title'));
          Board.Change(result.short_description, _.concat(_.initial(path), 'description'));
        })),
        prehead: ui`Text`({
          label: 'Document Prehead',
          controlled: 'value',
          visible: when`../document`.is.present.then(true).otherwise(false)
        }),
        title: ui`Text`({
          label: 'Document Title',
          controlled: 'value',
          visible: when`../document`.is.present.then(true).otherwise(false)
        }),
        description: ui`Text`({
          label: 'Document Description',
          controlled: 'value',
          visible: when`../document`.is.present.then(true).otherwise(false)
        })
      })({
        singular: 'Document',
        title: 'title',
        label: 'Documents',
      })
    },
    old_view: async ({ items } = {}) => {
      return Promise.all((items||[]).map(({ document, prehead, title, description, type, link }) => {
        if (!document) return null;
        return (document.__data ? Promise.resolve(document.__data) : expandRef(document)).then(result => {
          return {
            prehead,
            imgSize: 'document',
            aspectRatio: '0.77',
            image: result.thumb ? {
              alt: title,
              file: result.thumb.url
            } : null,
            url: type === 'link' ? link : `https://www.owenscorning.com/dms/${result.publication_id}`,
            heading: title,
            text: description,
            target: '_blank',
            linkText: <FileBadge extension={ getExtension(result.url) } sizeBytes={ result.size_bytes } />,
            analytics: {
              ...result.analytics,
              'element-location': "main section text link"
            }
          };
        })
      }).filter(Boolean));
    }
  },
  CtaDocumentSet: {
    availableIn: ['www.owenscorning.com', 'www.paroc.com'],
    name: 'DMS Document',
    meta: {
      item: ui`Form`.of({
        document: ui`Search`(refSearchMetadata(false, (x) => x)),
      })({
        singular: 'Document',
        title: 'title',
        label: 'Documents',
      })
    },
    old_view: async ({ item } = {}) => ( await expandRef(item?.document))
  },
  Search: {
    name: 'DMS Document',
    meta: {},
    search: async ({ filter } = {}) => {
      if (!filter) {
        return null;
      }
      if (searchAbortController) searchAbortController.abort();
      searchAbortController = new AbortController();
      return (await apiGet(`/api/v1/dms/document/search?source=dms2&q=${ filter }`, { signal: searchAbortController.signal })).map(result => (
        {
          id: result.publication_id,
          'Pub ID': result.publication_id,
          Title: result.title,
          Description: result.short_description,
          'Doc Type': getFormatFromMime(result.mime_type)
        }
      ))
    },
    preview: (result) => result && <FeaturedDocument
                                     title={ null }
                                     description={ <div style={ { marginTop: '-16px' } }>
                                       <b style={ { fontSize: '16px' } }>Pub ID: { result.publication_id }</b><br />
                                       <div style={ { fontSize: '12px' } }>
                                       Title: { result.title }<br />
                                       Description: { result.short_description }<br />
                                       Doc Type: { getFormatFromMime(result.mime_type) }
                                       </div>
                                     </div> }
                                     url={ result.url }
                                     thumbUrl={ result.thumb?.url }
                                     hideBadge
                                     pubId={ result.publication_id } />
  },
  BuilderDocument: {
    availableIn: ['www.owenscorning.com'],
    name: 'DMS Document',
    meta: {
      document: ui`Search`(refSearchMetadata(false, (result, path) => {
        Board.Change(result.url, _.concat(_.initial(path), 'url'));
        Board.Change(result.mime_type, _.concat(_.initial(path), 'mime_type'));
        Board.Change(result.size_bytes, _.concat(_.initial(path), 'size_bytes'));
        Board.Change(result.thumb?.url, _.concat(_.initial(path), 'thumbnail'));
        Board.Change(result.title, _.concat(_.initial(path), 'title'));
        Board.Change(result.short_description, _.concat(_.initial(path), 'description'));
      })),
      title: ui`Text`({
        label: 'Document Title',
        controlled: 'value',
        visible: when`../document`.is.present.then(true).otherwise(false)
      }),
      description: ui`Text`({
        label: 'Document Description',
        controlled: 'value',
        visible: when`../document`.is.present.then(true).otherwise(false)
      })
    },
    old_view: async ({ item } = {}) => ( await expandRef(item?.document))
  },
}

export default mapping;
