import React from 'react';

import Styles from './styles';
import { AuthenticReview } from '../AuthenticReview';
import { BazzarvoiceReviews } from '../BazzarvoiceReviews';
import { ContactUs } from '../ContactUs';
import { PopupIcon } from '../PopupIcon';

const ProductReviewLandingView = ({
  // eslint-disable-next-line react/prop-types
  precontent,
  // eslint-disable-next-line react/prop-types
  content,
}) => (
  <div css={Styles}>
    <div id="photo-viewer-modal" />

    <div className="product-review-landing__layout">
      <AuthenticReview
        preheader="Authentic reviews"
        header="from customers like you"
        precontent={precontent}
        content={content}
      />

      <PopupIcon />

      <BazzarvoiceReviews />

      <div className="product-review-landing__disclaimer">
        *Shingle products may vary by region. Reviews are based on the
        reviewer’s actual experience with the product and reflect the honest
        beliefs and experiences of the reviewer.
      </div>

      <div className="product-review-landing__black-devider" />

      <div>
        <ContactUs />
      </div>
    </div>
  </div>
);

export { ProductReviewLandingView };
